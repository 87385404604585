// hotfix to be removed later when refactoring/removing ThemeProvider from shakra, legacy design system
body {
  background-color: #fff !important;
}

.red-bg > td {
  background-color: #f5e6e6;
}

green-bg:hover > * {
  background-color: #D7EFE1 !important;
}

.green-bg
  td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last,
.green-bg
  td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background-color: #E6F5EC !important;
}

.green-bg td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-row-hover,
.green-bg td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-row-hover {
  background-color: #E6F5EC !important;
}

.green-bg:hover
  td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last,
.green-bg:hover
  td.ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background-color: #D7EFE1 !important;
}

.green-bg:hover > td.ant-table-cell-row-hover{
  background-color: #D7EFE1 !important;
}

.ant-table-tbody > tr.red-bg:hover > td {
  background: #efd7d7;
}

.red-bg .ant-table-column-sort {
  background-color: #efd7d7;
}

.green-bg > td {
  background-color: #e6f5ec;
}

.ant-table-tbody > tr.green-bg:hover > td {
  background: #d7efe1;
}

.green-bg .ant-table-column-sort {
  background-color: #e6f5ec !important; 
}

.green-bg:hover .ant-table-column-sort {
  background-color: #d7efe1 !important; 
}

.react-datepicker-wrapper {
  width: 100%;
}

.ant-btn {
  display: flex;
  align-items: center;
}

.ant-modal-footer,
.ant-modal-confirm-btns {
  display: flex;
  justify-content: flex-end;
}

.ant-collapse-header {
  display: flex;
  align-items: baseline;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label{
  width: calc(30% - 12px) !important;
}
.ant-timeline-label .ant-timeline-item-tail, 
.ant-timeline-label .ant-timeline-item-head-custom{
  inset-inline-start: calc(30%) !important;
}

.ant-timeline .ant-timeline-item-content{
  inset-inline-start: calc(30% - 4px) !important;
  width: calc(55% - 12px) !important;
}
.ant-timeline .ant-timeline-item-content > div {
  font-size: 15px !important;
}


@font-face {
  font-family: 'TTNormsPro-Regular';
  src: url('./assets/webfonts/TTNormsPro-Regular.eot');
  src:
    url('./assets/webfonts/TTNormsPro-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/webfonts/TTNormsPro-Regular.woff2') format('woff2'),
    url('./assets/webfonts/TTNormsPro-Regular.woff') format('woff'),
    url('./assets/webfonts/TTNormsPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'TTNormsPro-Bold';
  src: url('./assets/webfonts/TTNormsPro-Bold.eot');
  src:
    url('./assets/webfonts/TTNormsPro-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/webfonts/TTNormsPro-Bold.woff2') format('woff2'),
    url('./assets/webfonts/TTNormsPro-Bold.woff') format('woff'),
    url('./assets/webfonts/TTNormsPro-Bold.ttf') format('truetype');
}

@primary-color: #38a169;@zindex-modal: 900;@zindex-modal-mask: 900;